import React, { useState } from "react";
import { Link } from "gatsby";
import useSiteMetaData from "./SiteMetadata.js";
import ArrowDown from "../svg-icons/arrow-down.js";
import AnimateHeight from "react-animate-height";

const Navbar = () => {
  const [navBarActiveClass, setNavBarActiveClass] = useState("");
  const [navBarChildActiveClass, setNavBarChildActiveClass] = useState("");
  const { title } = useSiteMetaData();

  const toggleHamburger = () => {
    navBarActiveClass ? setNavBarActiveClass("") : setNavBarActiveClass("is-active");
  };

  const toggleChild = () => {
    window.innerWidth < 1024 && (navBarChildActiveClass ? setNavBarChildActiveClass("") : setNavBarChildActiveClass("active"));
  };

  const categories = [
    { title: "Case Fans", path: "case-fans" },
    { title: "Comparisons", path: "comparisons" },
    { title: "CPU Coolers", path: "cpu-coolers" },
    { title: "CPUs", path: "cpus" },
    { title: "Graphics Cards", path: "graphics-cards" },
    { title: "Keyboards", path: "keyboards" },
    { title: "Laptops", path: "laptops" },
    { title: "Monitors", path: "monitors" },
    { title: "Motherboards", path: "motherboards" },
    { title: "Mouse", path: "mouse" },
    { title: "PC Builds", path: "pc-builds" },
    { title: "PC Cases", path: "pc-cases" },
    { title: "Peripherals", path: "peripherals" },
    { title: "Power Supplies", path: "power-supplies" },
    { title: "RAMs", path: "rams" },
    { title: "Storage", path: "storage" },
    { title: "Thermal Paste", path: "thermal-paste" },
  ];

  const AddChilds = ({ data }) => {
    return data.map((item, index) => (
      <Link to={`/${item.path}/`} key={index}>
        {item.title}
      </Link>
    ));
  };

  return (
    <header id="masthead" itemType="https://schema.org/WPHeader" itemScope="itemscope" itemID="#masthead">
      <nav className="navbar is-transparent" role="navigation" aria-label="main-navigation">
        <div className="container">
          <div className="navbar-brand">
            <Link to="/" className="logo-container" title={title}>
              <img src="/img/logo.jpg" alt={title} loading="lazy" width="187" height="60" />
            </Link>
            {/* Hamburger menu */}
            <div className={`navbar-burger burger ${navBarActiveClass}`} data-target="navMenu" onClick={() => toggleHamburger()}>
              <span />
              <span />
              <span />
            </div>
          </div>
          <AnimateHeight id="navMenu" height={navBarActiveClass ? "auto" : 0} className="navbar-menu">
            <div className="navbar-end has-text-centered">
              <Link className="navbar-item" to="/learning-guides/">
                Learning Guides
              </Link>
              <span className={`navbar-item nav-parent ${navBarChildActiveClass}`}>
                <div className="parent-data">
                  <Link className="navbar-item" to="/buying-guides/">
                    Buying Guides
                  </Link>
                  <span className="open-parent" onClick={() => toggleChild()}>
                    <ArrowDown />
                  </span>
                </div>
                <AnimateHeight className="nav-child-container" height={navBarChildActiveClass ? "auto" : 0}>
                  <nav className="nav-child">
                    <AddChilds data={categories} />
                  </nav>
                </AnimateHeight>
              </span>
              <Link className="navbar-item" to="/news/">
                News
              </Link>
              <Link className="navbar-item" to="/reviews/">
                Reviews
              </Link>
            </div>
          </AnimateHeight>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
