import React from "react";
import { Helmet } from "react-helmet";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import BreadCrumbs from "../components/BreadCrumbs.js";
import "./css/main.css";
import { withPrefix } from "gatsby";

const TemplateWrapper = (props) => {
  const { children, title, titleParent, link, bodyAttributes } = props;

  return (
    <div>
      <Helmet bodyAttributes={bodyAttributes}>
        <html lang="en" />
        <link rel="apple-touch-icon" sizes="180x180" href={`${withPrefix("/")}img/apple-touch-icon.png`} />
        <link rel="icon" type="image/png" href={`${withPrefix("/")}img/favicon-32x32.jpg`} sizes="32x32" />
        <link rel="icon" type="image/png" href={`${withPrefix("/")}img/favicon-16x16.jpg`} sizes="16x16" />
        <link rel="mask-icon" href={`${withPrefix("/")}img/safari-pinned-tab.svg`} color="#ff4400" />
        <meta name="google-site-verification" content="gbZp_ObzDX-U4322mRwPLqsmd9GYidxMoWeHv75JnaQ" />

        {/* Global site tag (gtag.js) - Google Analytics */}
        <script defer src="https://www.googletagmanager.com/gtag/js?id=UA-86429262-12"></script>
        <script defer>{`function gtag(){dataLayer.push(arguments)}window.dataLayer=window.dataLayer||[],gtag("js",new Date),gtag("config","UA-86429262-12");`}</script>
      </Helmet>
      <Navbar />
      {title && <BreadCrumbs title={title} titleParent={titleParent} link={link} />}
      {children}
      <Footer />
    </div>
  );
};

export default TemplateWrapper;
