import React, { useState } from "react";
import { Link } from "gatsby";
import SocialIcons from "./SocialIcons.js";
import { Helmet } from "react-helmet";
import useSiteMetadata from "./SiteMetadata";

const Footer = () => {
  const [footerBarActiveClass, setFooterBarActiveClass] = useState("");
  const [footerActive, setFooterActive] = useState(false);
  const { title } = useSiteMetadata();

  const toggleFooterHamburger = () => {
    !footerActive ? setFooterBarActiveClass("is-active") : setFooterBarActiveClass("");
    setFooterActive(!footerActive);
  };

  return (
    <footer className="footer" id="colophon" itemType="https://schema.org/WPFooter" itemScope="itemscope" itemID="#colophon">
      <div className="footer-bottom">
        <div className="footer-logo has-text-centered">
          <div className="logo-container">
            <Link to="/">
              <img src="/img/logo-large.jpg" alt={title} loading="lazy" width="280" height="90" />
            </Link>
          </div>
        </div>
        {/* Hamburger menu */}
        <div className="footernav has-text-centered">
          <div className={`footer-burger burger ${footerBarActiveClass}`} data-target="footerMenu" onClick={() => toggleFooterHamburger()}>
            <span />
            <span />
            <span />
          </div>
          <div id="footerMenu" className={`footer-menu ${footerBarActiveClass}`}>
            <div className="footerbar">
              <Link className="footerbar-item" to="/about-us/">
                About Us
              </Link>
              <Link className="footerbar-item" to="/contact-us/">
                Contact Us
              </Link>
              <Link className="footerbar-item" to="/affiliate-disclosure/">
                Affiliate Disclosure
              </Link>
              <Link className="footerbar-item" to="/terms-of-service/">
                Terms of Service
              </Link>
              <Link className="footerbar-item" to="/privacy-policy/">
                Privacy Policy
              </Link>
              <a className="footerbar-item" href="/sitemap.xml">
                Sitemap
              </a>
            </div>
          </div>

          <SocialIcons />
          <div className="footer-text">
            <div className="protected-img">
              <a href="https://www.dmca.com/Protection/Status.aspx?ID=a402cd6b-f9a0-4bd6-9221-3880bf2baff4&refurl=https://www.protechreviewer.com/">
                <img src="/img/dmca.png" alt="DMCA.com Protection Status" loading="lazy" width="121" height="24" />
              </a>
            </div>
            <p>ProTechReviewer © 2021. All rights reserved.</p>
          </div>
        </div>
      </div>
      <Helmet>
        {/* Facebook Pixel Code*/}
        <script defer>{`setTimeout(function(){!function(e,t,n,c,o,a,f){e.fbq||(o=e.fbq=function(){o.callMethod?o.callMethod.apply(o,arguments):o.queue.push(arguments)},e._fbq||(e._fbq=o),o.push=o,o.loaded=!0,o.version="2.0",o.queue=[],(a=t.createElement(n)).async=!0,a.src="https://connect.facebook.net/en_US/fbevents.js",(f=t.getElementsByTagName(n)[0]).parentNode.insertBefore(a,f))}(window,document,"script"),fbq("init","552202589047504"),fbq("track","PageView");}, 3000);`}</script>
        <noscript>{`<img height="1" width="1" style="display: none" src="https://www.facebook.com/tr?id=552202589047504&ev=PageView&noscript=1" />`}</noscript>
      </Helmet>
    </footer>
  );
};

export default Footer;
