import React from "react";
import { Helmet } from "react-helmet";
import useSiteMetadata from "./SiteMetadata";

const HeadData = (props) => {
  const { siteURL, title: siteName } = useSiteMetadata();
  const { title, description, image, schema, bodyAttributes } = props;
  const sitemapschema = `{
    "@context":"https://schema.org",
    "@graph":[
      {"@context":"https://schema.org","@type":"SiteNavigationElement","@id":"#Primary","name":"Learning Guides","url":"${siteURL}/learning-guides/"},
      {"@context":"https://schema.org","@type":"SiteNavigationElement","@id":"#Primary","name":"Buying Guides","url":"${siteURL}/buying-guides/"},
      {"@context":"https://schema.org","@type":"SiteNavigationElement","@id":"#Primary","name":"News","url":"${siteURL}/news/"},
      {"@context":"https://schema.org","@type":"SiteNavigationElement","@id":"#Primary","name":"Reviews","url":"${siteURL}/reviews/"}]}`;
  const index = props.index !== false;

  return (
    <Helmet bodyAttributes={bodyAttributes}>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="theme-color" content="#fff" />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="article" />
      <meta property="og:title" content={`${title}`} />
      <meta property="og:description" content={description} />
      <meta property="og:site_name" content={siteName} />
      <meta property="og:image" content={`${siteURL}/img/${image || "logo-x-large.jpg"}`} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content="@SEO_Aleem" />
      <meta name="twitter:site" content="@SEO_Aleem" />
      <script type="application/ld+json">{sitemapschema}</script>
      {index ? <meta name="robots" content="index, follow" /> : <meta name="robots" content="noindex" />}
      {index && <meta name="googlebot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />}
      {index && <meta name="bingbot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />}
      {schema && <script type="application/ld+json">{schema}</script>}
      {props.children}
    </Helmet>
  );
};

export default HeadData;
